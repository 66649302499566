import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteObject } from 'react-router'

import BaseLayout from 'src/layouts/BaseLayout'

import SuspenseLoader from 'src/components/SuspenseLoader'

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
)

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')))
const Signin = Loader(lazy(() => import('src/content/signin')))

// Status
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')))
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')))
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')))
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')))

const routes: RouteObject[] = [
    {
        path: '',
        element: <BaseLayout />,
        children: [
            {
                path: '/',
                element: <Overview />
            },
            {
                path: 'overview',
                element: <Navigate to="/" replace />
            },
            {
                path: 'signin',
                element: <Signin />
            },
            {
                path: 'status',
                children: [
                    {
                        path: '',
                        element: <Navigate to="404" replace />
                    },
                    {
                        path: '404',
                        element: <Status404 />
                    },
                    {
                        path: '500',
                        element: <Status500 />
                    },
                    {
                        path: 'maintenance',
                        element: <StatusMaintenance />
                    },
                    {
                        path: 'coming-soon',
                        element: <StatusComingSoon />
                    }
                ]
            },
            {
                path: '*',
                element: <Status404 />
            }
        ]
    }
];

export default routes;