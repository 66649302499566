import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteObject } from 'react-router'

import SidebarLayout from 'src/layouts/SidebarLayout'

import SuspenseLoader from 'src/components/SuspenseLoader'

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);


// ผู้ใช้งาน
const Dashboard = Loader(lazy(() => import('src/content/dashboards')))
const House = Loader(lazy(() => import('src/content/houses')))
const HousesDetails = Loader(lazy(() => import('src/content/housesDetails')))
const HouseChart = Loader(lazy(() => import('src/content/houseChart')))
const Egg = Loader(lazy(() => import('src/content/egg')))
const Statistics = Loader(lazy(() => import('src/content/statistics')))

//Administtrotr
const Customer = Loader(lazy(() => import('src/content/administrators/Customer')))
const CustomerDetails = Loader(lazy(() => import('src/content/administrators/CustomerDetails')))
const CustomerHousesDetails = Loader(lazy(() => import('src/content/administrators/CustomerHousesDetails')))
const User = Loader(lazy(() => import('src/content/administrators/User')))
const Setting = Loader(lazy(() => import('src/content/administrators/Setting')))

//profile
const ProfilePage = Loader(lazy(() => import('src/content/profile')))

// Status
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'signin',
        element: <Dashboard />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'house',
        children: [
          {
            path: '',
            element: <Navigate to="index" replace />
          },
          {
            path: 'index',
            element: <House />
          },
          {
            path: 'details',
            element: <HousesDetails />
          },
          {
            path: 'chart',
            element: <HouseChart />
          }
        ]
      },
      {
        path: 'egg',
        children: [
          {
            path: '',
            element: <Navigate to="index" replace />
          },
          {
            path: 'index',
            element: <Egg />
          },
          {
            path: 'statistics',
            element: <Statistics />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'administrator',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="customer" replace />
      },
      {
        path: 'customer',
        element: <Customer />
      },
      {
        path: 'customerDetails',
        element: <CustomerDetails />
      },
      {
        path: 'housesDetails',
        element: <CustomerHousesDetails />
      },
      {
        path: 'user',
        element: <User />
      },
      {
        path: 'Setting',
        element: <Setting />
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/details" replace />
      },
      {
        path: 'details',
        element: <ProfilePage />
      },
    ]
  }
];

export default routes;
