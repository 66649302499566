import React from 'react'
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import routerAuth from 'src/routerAuth'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import { AppContext } from './contexts/context'
import appService from './service/appService'

function App() {
  const content = useRoutes(router);
  const contentAuth = useRoutes(routerAuth);

  const initialLoginState: LoginState = {
    isLoading: true,
    userToken: null
  }

  const loginReducer = (prevState: any, action: { type: string; token: string; }) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false
        };
      case 'LOGIN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false
        };
      case 'LOGOUT':
        return {
          ...prevState,
          userToken: null,
          isLoading: false
        };
      case 'REGISTER':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false
        };

      default:
        return {
          ...prevState,
          isLoading: false
        };
    }
  }

  const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState)
  const appContext = React.useMemo(() => ({

    signIn: async (body: ISigninBodyRequest): Promise<boolean> => {
      try {
        const result = await appService.post('auth/login', body)
        const data: ISigninBodyResponse = result.data

        localStorage.setItem('accressToken', JSON.stringify(data.accressToken))
        dispatch({ type: 'LOGIN', token: data.accressToken });
        return true
      } catch (error) {
        return false
      }
    },

    signOut: async (): Promise<void> => {
      try {
        localStorage.clear()
      } catch (error) {
        console.log(error);
      }
      dispatch({ type: 'LOGOUT', token: null });
    },

    checkAuth: async (): Promise<void> => {
      const herder = await getAccressTokenWithLocalStorage()

      try {
        await appService.post('auth/check', null, { headers: herder })
      } catch (error) {
        localStorage.clear()
        dispatch({ type: 'LOGOUT', token: null });
      }
    },

    refreshToken: async (): Promise<void> => {
      const herder = await getAccressTokenWithLocalStorage()
      try {
        const result = await appService.post('auth/refreshToken', null, { headers: herder })
        const data: ISigninBodyResponse = result.data

        localStorage.setItem('accressToken', JSON.stringify(data.accressToken))
        dispatch({ type: 'RETRIEVE_TOKEN', token: data.accressToken });
      } catch (error) {
        console.log(error)
      }
    },

    getProfile: async (): Promise<IProfileBodyResponse> => {
      const herder = await getAccressTokenWithLocalStorage()

      let result: IProfileBodyResponse = null
      try {
        const res = await appService.get('/auth/profile', { headers: herder })
        result = res.data
      } catch (error) {
        console.log(error)
      }
      return result
    },

    getData: async (url: string): Promise<any> => {
      const herder = await getAccressTokenWithLocalStorage()
      let result: any = null
      try {
        const res = await appService.get(url, { headers: herder })
        result = res.data
      } catch (error) {

      }
      return result
    },

    postData: async (url: string, body: any): Promise<void> => {
      const herder = await getAccressTokenWithLocalStorage()
      try {
        await appService.post(url, body, { headers: herder })
      } catch (error) {

      }
    },

    putData: async (url: string, body: any): Promise<void> => {
      const herder = await getAccressTokenWithLocalStorage()
      try {
        await appService.put(url, body, { headers: herder })
      } catch (error) {

      }
    },

    deleteData: async (url: string): Promise<void> => {
      const herder = await getAccressTokenWithLocalStorage()
      try {
        await appService.delete(url, { headers: herder })
      } catch (error) {

      }
    }

  }), [])

  const getAccressTokenWithLocalStorage = async (): Promise<any> => {
    let headers = {
      'Authorization': 'bearer'
    }
    try {
      const accressToken = await localStorage.getItem('accressToken')
      headers.Authorization = `bearer ${JSON.parse(accressToken)}`
    } catch (error) {
      console.log(error)
    }

    return headers
  }

  React.useEffect(() => {

    const intiPage = async () => {
      let accressToken = null
      try {
        accressToken = await localStorage.getItem('accressToken')
      } catch (error) {
        console.log(error)
      }
      dispatch({ type: 'RETRIEVE_TOKEN', token: accressToken })
    }

    intiPage()
  }, [])

  return (
    <AppContext.Provider value={appContext}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {loginState.userToken != null ? (
            <React.Fragment>
              {content}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {contentAuth}
            </React.Fragment>
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
